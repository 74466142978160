.label {
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.8;
  padding: 3px 10px;
  border-radius: 40px;
  max-width: max-content;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
}

.videoLabel {
  color: #e11a38;
  border: 1px solid #e11a38;
}
.shopLabel {
  color: #017dfb;
  border: 1px solid #017dfb;
}

@media screen and (min-width: 1024px) {
  .label {
    font-size: 18px;
    min-width: 114px;
    text-align: center;
  }
}
