.el1 {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  animation: loading 2s linear infinite;
}

.el2-wrap {
  position: absolute;
  width: 100%;
  height: 100%;
  transform: scale(0.65);
  top: 0;
  left: 0;
}

.el2 {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  animation: loading 2s linear infinite reverse;
}
