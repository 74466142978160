@keyframes loading {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.loading > div {
  position: absolute;
  width: 72px;
  height: 72px;
  border-radius: 50%;
  border: 8px solid #000;
  border-color: var(--loading-color-border-main) transparent
    var(--loading-color-border-main) transparent;
  animation: loading 1.282051282051282s linear infinite;
}

.loading > div:nth-child(2),
.loading > div:nth-child(4) {
  width: 54px;
  height: 54px;
  top: 9px;
  left: 9px;
  animation: loading 1.282051282051282s linear infinite reverse;
}

.loading > div:nth-child(2) {
  border-color: transparent var(--silver) transparent var(--silver);
}

.loading > div:nth-child(3) {
  border-color: transparent;
}

.loading > div:nth-child(3) div {
  position: absolute;
  width: 100%;
  height: 100%;
  transform: rotate(45deg);
}

.loading > div:nth-child(3) div:before,
.loading > div:nth-child(3) div:after {
  content: '';
  display: block;
  position: absolute;
  width: 8px;
  height: 8px;
  top: -8px;
  left: 24px;
  background: var(--loading-color-border-main);
  border-radius: 50%;
  box-shadow: 0 64px 0 0 var(--loading-color-border-main);
}

.loading > div:nth-child(3) div:after {
  left: -8px;
  top: 24px;
  box-shadow: 64px 0 0 0 var(--loading-color-border-main);
}

.loading > div:nth-child(4) {
  border-color: transparent;
}

.loading > div:nth-child(4) div {
  position: absolute;
  width: 100%;
  height: 100%;
  transform: rotate(45deg);
}

.loading > div:nth-child(4) div:before,
.loading > div:nth-child(4) div:after {
  content: '';
  display: block;
  position: absolute;
  width: 8px;
  height: 8px;
  top: -8px;
  left: 15px;
  background: var(--silver);
  border-radius: 50%;
  box-shadow: 0 46px 0 0 var(--silver);
}

.loading > div:nth-child(4) div:after {
  left: -8px;
  top: 15px;
  box-shadow: 46px 0 0 0 var(--silver);
}

.loading-wrapper {
  width: 80px;
  height: 80px;
  display: inline-block;
  overflow: hidden;
  background: transparent;
  position: absolute;
  z-index: 10;
}

.loading {
  width: 100%;
  height: 100%;
  position: relative;
  backface-visibility: hidden;
  transform-origin: 0 0;
}
