html {
  line-height: normal;
  /* 1 */
  -webkit-text-size-adjust: 100%;
  /* 2 */
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Sections
     ========================================================================== */

/**
   * Remove the margin in all browsers.
   */

body {
  margin: 0;
  padding: 0 !important;
  font-size: 18px;
}

h1,
h2,
h3,
h4,
p {
  margin: 0;
  padding: 0;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}