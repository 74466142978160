.root {
  max-width: 1720px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  padding-right: 20px;

  padding-top: 40px;
  padding-bottom: 40px;

  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  row-gap: 27px;
  justify-content: center;
}

h1 {
  font-size: 24px;
  text-align: center;
}

.underTitle {
  font-size: 14px;
  font-weight: 700;
  text-align: center;
  margin-top: 20px;
}

p {
  font-size: 14px;
  line-height: 1.5;
  margin-top: 20px;
}

.title {
  font-size: 24px;
  font-weight: 300;
  margin-top: 20px;
}

span {
  margin-right: 10px;
}

.bank {
  margin-top: 10px;
}

a {
  text-decoration: none;
  color: blue;
}

a:hover,
a:focus {
  color: darkorchid;
}

@media screen and (min-width: 720px) {
  h1 {
    font-size: 32px;
  }

  .underTitle {
    font-size: 16px;
  }

  p {
    font-size: 16px;
  }

  .title {
    font-size: 32px;
  }
}

@media screen and (min-width: 1280px) {
  .root {
    padding-left: 120px;
    padding-right: 120px;
  }
}

@media screen and (min-width: 1440px) {
  h1 {
    font-size: 42px;
  }

  .underTitle {
    font-size: 24px;
  }

  p {
    font-size: 24px;
  }

  .title {
    font-size: 42px;
  }
}
