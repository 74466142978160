.scroll::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

/* Track */
.scroll::-webkit-scrollbar-track {
  border-radius: 2px;
}

.scroll::-webkit-scrollbar-track {
  background: rgba(0, 41, 88, 1);
}

/* Handle */
.scroll::-webkit-scrollbar-thumb {
  border-radius: 4px;
}

.scroll::-webkit-scrollbar-thumb {
  background-color: rgba(0, 64, 134, 1);
}

/* Handle on hover */
.scroll::-webkit-scrollbar-thumb:hover {
  background: #004086;
}

.scroll::-webkit-scrollbar-corner {
  background-color: transparent;
}
