.root {
  width: 90vw;
  max-width: 711px;

  background-color: #fff;

  padding-top: 60px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
}

.content {
  color: #061229;
  text-align: center;
  font-family: 'Fira Sans Extra Condensed', sans-serif;
  font-size: 40px;
  font-weight: 900;
  line-height: 1.4;
  letter-spacing: 0.84px;
  text-transform: uppercase;

  margin-bottom: 30px;
}

.white {
  border: 1px solid #e11a38;
}

@media screen and (max-width: 374px) {
  .buttonGroup {
    display: grid;
    gap: 10px;
  }

  .buttonGroup > button {
    width: 100%;
  }
}

@media screen and (min-width: 375px) and (max-width: 479px) {
  .buttonGroup {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
  }
  .buttonGroup > button {
    width: 100%;
  }
}

@media screen and (min-width: 480px) {
  .root {
    padding-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;
  }
  .content {
    font-size: 42px;
    margin-bottom: 50px;
  }

  .buttonGroup {
    display: flex;
    gap: 21px;
    justify-content: center;
  }

  .button {
    min-width: 160px;
  }
}

@media screen and (min-width: 720px) {
  .root {
    padding: 74px;
  }
}
