.reset {
  font-family: 'Roboto', sans-serif;
  border: none;
  font-weight: 400;
}

.animation {
  transition-property: background-color, box-shadow, border-color, color, fill;
  transition-duration: 250ms, 250ms, 250ms, 250ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1),
    cubic-bezier(0.4, 0, 0.2, 1), cubic-bezier(0.4, 0, 0.2, 1),
    cubic-bezier(0.4, 0, 0.2, 1);
  cursor: pointer;

  border: none;
}

.button {
  font-size: 18px;

  padding-left: 17px;
  padding-right: 17px;

  border-radius: 80px;
}

.button svg {
  padding-left: 5px;
  height: 18px;
}

.red {
  color: #fff;
  background-color: #e11a38;
}

.red:hover,
.red:focus {
  box-shadow: 3px 6px 24px 0px rgba(225, 26, 56, 0.4);
}

.red:active {
  background-color: #ca1833;
}

.white {
  background-color: #fff;
  color: rgba(225, 26, 56, 1);
}

.white:hover,
.white:focus {
  background: #fedfe4;
}

.white:active {
  background: #ff95a7;
}

.basic {
  background-color: transparent;
  padding: 0px;
  color: #e11a38;
}

.basic:hover,
.basic:focus {
  color: #ff95a7;
}

.basic:active {
  color: #ca1833;
}

.slider {
  background-color: #fff;
  color: rgba(1, 125, 251, 1);
  fill: rgba(1, 125, 251, 1);
}

.slider:hover,
.slider:focus {
  background-color: rgba(217, 230, 244, 1);
}

.slider:active {
  background-color: rgba(1, 125, 251, 1);
  color: white;
  fill: white;
}

.gray {
  color: rgba(174, 166, 166, 1);
  fill: rgba(174, 166, 166, 1);
  background-color: rgba(234, 234, 234, 1);
}

.gray:hover,
.gray:focus {
  color: rgba(140, 140, 140, 1);
  fill: rgba(140, 140, 140, 1);
}

.gray:active {
  color: rgba(255, 255, 255, 1);
  background-color: rgba(148, 148, 148, 1);
}

/* @media screen and (max-width: 1023px) {
  .button {
    padding-top: 18px;
    padding-bottom: 18px;
  }
}

@media screen and (min-width: 1024px) {
  .button {
    padding-top: 21px;
    padding-bottom: 21px;
    font-size: 18px;
  }
} */