.popup {
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(128, 173, 219, 0.7);
  opacity: 0;
  visibility: hidden;
  -webkit-transition: opacity 0.3s 0s, visibility 0s 0.3s;
  -moz-transition: opacity 0.3s 0s, visibility 0s 0.3s;
  transition: opacity 0.3s 0s, visibility 0s 0.3s;
  z-index: 101;
}

.popup.is-visible {
  opacity: 1;
  visibility: visible;
  -webkit-transition: opacity 0.3s 0s, visibility 0s 0s;
  -moz-transition: opacity 0.3s 0s, visibility 0s 0s;
  transition: opacity 0.3s 0s, visibility 0s 0s;
}

.popup-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) !important;
  border-radius: 0.2em;
  z-index: 15;
  -webkit-backface-visibility: hidden;
  -webkit-transition-property: -webkit-transform;
  -moz-transition-property: -moz-transform;
  transition-property: transform;
  -webkit-transition-duration: 0.3s;
  -moz-transition-duration: 0.3s;
  transition-duration: 0.3s;
  overflow: auto;

  border-radius: 30px;
}

.popup-dimmer {
  height: 100%;
}

.popup-container .popup-close::before,
.popup-container .popup-close::after {
  background-color: var(--primary-text-color);
}

.modal-size {
  max-width: 90vw;
  max-height: 90vh;
}

.is-visible .popup-container {
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
}

.body-overflow-hidden {
  overflow: hidden;
}

.close-modal-button {
  background-color: transparent;
  border: none;
  cursor: pointer;

  position: absolute;
  top: 20px;
  right: 20px;

  width: 40px;
  height: 40px;

  display: flex;
  align-items: center;
  justify-content: center;

  padding: 0px !important;

  z-index: 1;
}
