.reviews-slider-wrapper {
  padding-bottom: 40px;
  max-width: 996px;
  margin: auto;
}

.reviews-slider-wrapper .slick-track {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: stretch;
}

.reviews-slider-wrapper .slick-slide {
  height: auto !important;
}

.reviews-slider-wrapper .slick-slide > div {
  height: 100% !important;
}

.reviews-slider-wrapper .slick-slide > div > div {
  height: 100% !important;
}

/* ========================================================= */

.slider-header {
  color: #fff;
  font-family: 'Fira Sans Extra Condensed';
  font-size: 100px;
  font-weight: 900;
  text-transform: uppercase;

  margin: 0 auto;
  padding-bottom: 45px;
  max-width: 1300px;
  text-indent: 160px;
}

.reviews-slider-item {
  display: flex;
  width: 95%;
  margin: 0 auto;

  background: #061229;
  border-radius: 30px;
  height: 100%;
}

.reviews-slider-item:not(:last-child) {
  margin-right: 20px;
}

.reviews-item-header-wrapper {
  align-items: center;
  margin-right: 20px;
}

.reviews-icon-wrap {
  width: 60px;
  height: 60px;
  background-color: rgba(1, 125, 251, 1);
  border-radius: 50%;
  padding: 17px;
}

.reviews-item-icon {
  width: 45px;
  height: 30px;
  margin-left: auto;
  margin-right: 30px;
}

.reviews-slider-title {
  color: #fff;
  font-family: 'Fira Sans Extra Condensed', sans-serif;
  font-size: 20px;
  font-weight: 900;
  line-height: 1.8;
}

.reviews-slider-text {
  color: #fff;
  font-weight: 400;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  line-height: 1.8;
  padding: 5px;
}

.slick-list {
  width: 100%;
}

.slick-initialized {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
}

.slick-initialized button {
  position: relative;
  width: 55px;
}

.slider-image-button-left .slider-image-button-icon-left {
  position: absolute;
  top: 17px;
}

.slider-image-button-right .slider-image-button-icon-right {
  position: absolute;
}

.slider-image-button {
  display: flex !important;
  align-items: center;
  justify-content: center;

  border-radius: 50%;
  width: 50px;
  height: 50px;
  padding: 0 !important;
}

.slider-image-button-icon {
  display: block;

  height: 20px;
  width: 22px;
}

.slider-image-button-icon-left {
  transform: rotate(-135deg);
}

.slider-image-button-icon-right {
  transform: rotate(45deg);
}

.reviewsAnimationInitial {
  transform: translateY(120px);
  opacity: 0.3;
}

@media screen and (max-width: 768px) {
  .reviews-slider-wrapper {
    padding-bottom: 0px;
  }

  .slider-header {
    font-size: 53px;
    text-indent: 20px;
  }

  .reviews-slider-item {
    flex-direction: column;
  }

  .slick-track {
    margin-bottom: 70px;
  }

  .slick-initialized .slider-image-button-left {
    position: absolute;
    bottom: 10px;
    left: 10px;
  }

  .slick-initialized .slider-image-button-right {
    position: absolute;
    bottom: 10px;
    right: 10px;
  }
}

@keyframes animation {
  from {
    transform: translateY(120px);
    opacity: 0.3;
  }

  to {
    opacity: 1;
    transform: translateY(0px);
  }
}

.reviewsAnimation {
  animation-name: animation;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}
