.root {
  width: 90vw;
  max-width: 590px;

  background-color: #fff;

  padding-top: 60px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 60px;
}

.content {
  color: #061229;
  text-align: center;
  font-family: 'Fira Sans Extra Condensed', sans-serif;
  /* font-size: 60px; */
  font-size: 40px;
  font-weight: 900;
  line-height: 1.4;
  letter-spacing: 1.2px;
  text-transform: uppercase;

  max-width: 443px;
  margin: 0 auto;
}

@media screen and (min-width: 360px) {
  .root {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media screen and (min-width: 480px) {
  .content {
    font-size: 60px;
  }
}

@media screen and (min-width: 720px) {
  .root {
    padding: 75px;
  }
}
