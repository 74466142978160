.wrapper {
  background: transparent;
}

.root {
  position: relative;
  background-color: #fff;
  padding: 20px;
  width: 90vw;
  max-width: 580px;
  border-radius: 30px;
}

.index {
  width: 84px;
  height: 84px;
  padding: 26px 32px;
  background-color: #017dfb;
  border-radius: 50%;

  font-family: 'Roboto', sans-serif;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0.54px;

  margin-bottom: 28px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.header {
  color: #061229;
  font-family: 'Fira Sans Extra Condensed', sans-serif;
  font-size: 20px;
  font-weight: 900;
  line-height: 1.8;
  letter-spacing: 0.4px;
}

.listItem {
  display: flex;
  gap: 8px;
}

.marker {
  color: #e11a38;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.8;
}

.listItemContent {
  color: #000;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.8;
}

.border {
  margin-top: 44px;
  margin-bottom: 28px;
  border-bottom: 1px solid #000;
}

.listItem:not(:last-child) {
  margin-bottom: 18px;
}

@media screen and (max-width: 359px) {
  .label {
    margin-bottom: 10px;
  }
}
@media screen and (min-width: 360px) {
  .headerWrapper {
    display: flex;
  }

  .workShopWrapper {
    display: flex;
  }

  .label {
    margin-top: 3px;
  }
}

@media screen and (max-width: 719px) {
  .border {
    margin-right: -20px;
    margin-top: 44px;
    margin-bottom: 28px;
  }
  .workShopWrapper {
    gap: 19px;
  }
  .headerWrapper {
    gap: 8px;
    margin-bottom: 32px;
  }
}

@media screen and (min-width: 720px) {
  .wrapper {
    padding: 50px;
    /* background-color: transparent; */
  }

  .root {
    padding: 53px 50px;
  }

  .index {
    position: absolute;
    /* margin-top: -42px;
    margin-left: -42px; */
    top: -42px;
    left: -42px;
  }

  .headerWrapper {
    gap: 10px;
    margin-bottom: 29px;
  }

  .border {
    margin-top: 29px;
    margin-bottom: 33px;
  }

  .workShopWrapper {
    gap: 11px;
  }

  .header {
    font-size: 24px;
  }

  .marker,
  .listItemContent {
    font-size: 18px;
  }
}
